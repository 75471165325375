.swiper-container {
    transform: translateZ(0);
  }
  html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';